@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.article-text{
  margin-left:1em;
}
.article-news{
  width:100%;
}
.news-image{
  border-radius: 1em;
  object-fit: cover;
}
.news-image-container{
  display: flex;
  width: 100%;
}
a{
  text-decoration: none;
  color: black ;
}
.load-page{
  text-align: center;
  margin: 3em 0;
}
.news-text{
  display:block;
  font-size:15px;
}
.button_text{
  color:white;
  background-color: grey;
}
#state-map{
  background-color: rgb(250, 240, 230);
  height: auto;
  width: 100%; 
}
@media only screen and (max-width: 600px) {
  .news-text{
    display: none !important;
  }
  .news-image{
    width: 140px;
    height:90px;
  }
}
@media only screen and (min-width:600px){
   .news-image{
    width:250px;
    height:180px;
  }
}
@media only screen and (min-width:700px){
  #overall-map{
    height: 350px;
  }
}
@media only screen and (min-width:1000px){
  .home-page{
    width: 70%;
    margin: auto;
  } 
}
@media only screen and (min-width:1300px){
  .home-page{
    width: 60%;
  } 
}
@media only screen and (min-width:1800px){
  .home-page{
    width: 50%;
  } 
}
