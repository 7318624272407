.tableParty{
    border:10px solid rgb(255,179,0);
    border-top: none;
    border-bottom: none;
    table-layout:fixed;
    padding: 0px 10px !important;
  }
  .tableParty:first-child{
    border-top:10px solid rgb(255,179,0);
  }
  .tableParty:last-child{
    border-bottom:10px solid rgb(255,179,0) ;
  }
  .party_rows {
    background-color: white;
  }
  .td_partyName{
    width: 65% !important;
  }
  .td_party,.td_partyName{
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid black !important ;
  }
  .party_rows:last-child .td_party{
    border-bottom: 0;
  }
  .ant-table-thead>tr>th{
    background-color: rgb(200,200,200)  !important;
  }