.player-wrapper {
  position: relative;
  width:100%;
  padding-top: 56.25% ;
}
  
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}