.title_seat{
    text-align: center;
}
.title{
    font-weight: bold;
}
.button_expand{
    text-align: center;
    margin: 15px 0;
}
.chart{
  max-width: 1000px;
  margin: auto;
}
.chartNP{
    max-width: 1000px;
    margin: auto;
    /* margin-top: -40px; */
}
.seat_info{
    margin: auto;
    padding-bottom: 2em;
}
.collapse_seat{
    background-color: rgb(253,180,108) !important;
  }
.panel_seat{
  font-weight: 500;
}
.ant-collapse-content{
  background-color: rgb(247, 236, 198) !important;
}
.seat_map{
  text-align:center;
  margin:3em 0 ;
}
.map{
  margin: auto;
  max-width: 100%;
}
@media only screen and (min-width:600px){
    .seat_info{
        width: 80%;
    }
    .map{
      max-width: 80%;
    }
}
@media only screen and (min-width:800px){
    .seat_info{
        width: 70%;
      }
      .table_seat,.panel_seat{
        font-size:15px;
      }
      .map{
        max-width: 60%;
      }
}
@media only screen and (min-width:1000px){
    .map{
      max-width: 50%;
    }
    .seat_info{
        width: 60%;
      }
}
@media only screen and (min-width:1300px){
    .map{
        max-width: 40%;
    }
    .table_seat,.panel_seat{
      font-size:17px;
    }
}
@media only screen and (min-width:1800px){
      .map{
        max-width: 30%;
      }
      .seat_info{
        width: 50%;
      }
}