
@media only screen and (min-width:600px){
    .state_main{
        max-width: 80%;
        margin: auto ;
        justify-content: center;
        text-align: center;
      }
}
@media only screen and (min-width:700px){
    .table_party{
        max-width: 50%;
        margin: auto;
      }
}
@media only screen and (min-width:1000px){
    .state_main{
        max-width: 60%;
    }
}
@media only screen and (min-width:1300px){
    .table_party{
        max-width: 40%;
    }
    .state_main{
        display: flex;
    }
}
